import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'
import Login from './pages/mobile/login.vue'
import Register from './pages/mobile/register.vue'
import { Message } from 'element-ui'
import Reset from './pages/reset.vue'
import mNoticeDetails from '@/pages/mobile/mNoticeDetails.vue';

Vue.use(VueRouter)

//PS:   keepAlive: false是不需要缓存  true是缓存

// 移动端路由配置 mobileRoutes.js
const mobile = [{
    path: '/login',
    component: Login
},
{
    path: '/register',
    component: Register
}, {
    path: '/reset',
    component: Reset
}, {
    path: '/mindex',
    name: "mindex",
    component: () =>
        import('@/pages/mindex.vue'),
    meta: {
        keepAlive: true,
        requiresMobile: true,
    },
    props: true
},
{
    path: '/contact',
    component: () =>
        import('@/pages/mobile/contact.vue'),
    meta: {
        keepAlive: true,
        requiresMobile: true,

    },
    props: true
}, {
    path: '/cate',
    component: () =>
        import('@/pages/mobile/cate.vue'),
    meta: {
        title: '商品分类',
        keepAlive: true,
        requiresMobile: true,
    },
    props: true
}, {
    path: '/minvited',
    component: () =>
        import('@/pages/mobile/invited.vue'),
    meta: {
        title: '邀请好友',
        keepAlive: true,
        requiresMobile: true,
    },
    props: true
}, {
    path: '/openshop',
    component: () =>
        import('@/pages/mobile/openshop.vue'),
    meta: {
        title: '开通分站',
        keepAlive: false,
        requiresMobile: true,
    },
}, {
    path: '/afterSales',
    component: () =>
        import('@/pages/mobile/afterSales.vue'),
    meta: {
        title: '售后订单',
        keepAlive: false,
        requiresMobile: true,
    },
}, {
    path: '/amountDetail',
    component: () =>
        import('@/pages/mobile/amountDetail.vue'),
    meta: {
        title: '资金明细',
        keepAlive: false,
        requiresMobile: true,
    },
}, {
    path: '/hotProduct',
    component: () =>
        import('@/pages/mobile/hotProduct.vue'),
    meta: {
        keepAlive: false,
        requiresMobile: true,
    },
}, {
    path: '/product_list',
    component: () =>
        import('@/pages/mobile/productList.vue'),
    meta: {
        keepAlive: true,
        requiresMobile: true,
        //是否刷新
        isRefresh: false
    },
    props: true

}, {
    path: '/product_detail/:id',
    component: () =>
        import('@/pages/mobile/productDetail.vue'),
    meta: {
        keepAlive: false,
        requiresMobile: true,
    },
}, {
    path: '/setup',
    component: () =>
        import('@/pages/mobile/setup.vue'),
    meta: {
        keepAlive: false,
        requiresMobile: true,
    },
}, {
    path: '/my',
    component: () =>
        import('@/pages/mobile/my/index.vue'),
    meta: {
        title: '我的',
        keepAlive: false,
        requiresMobile: true,
    },
    props: true

}, {
    path: '/userlevel',
    component: () =>
        import('@/pages/mobile/userlevel.vue'),
    meta: {
        keepAlive: false,
        requiresMobile: true,
    },
}, {
    path: '/openVip',
    component: () =>
        import('@/pages/mobile/openVip.vue'),
    meta: {
        title: '开通分站',
        keepAlive: false,
        requiresMobile: true,
    },
}, {
    path: '/api',
    component: () =>
        import('@/pages/mobile/api.vue'),
    meta: {
        keepAlive: false,
        requiresMobile: true,
    },
}, {
    path: '/browselist',
    component: () =>
        import('@/pages/mobile/browselist.vue'),
    meta: {
        keepAlive: false,
        requiresMobile: true,
    },
}, {
    path: '/collectlist',
    component: () =>
        import('@/pages/mobile/collectlist.vue'),
    meta: {
        keepAlive: false,
        requiresMobile: true,
    },
}, {
    path: '/search',
    component: () =>
        import('@/pages/mobile/search/index.vue'),
    meta: {
        keepAlive: false,
        requiresMobile: true,
    },
}, {
    path: '/payment',
    component: () =>
        import('@/pages/mobile/payment/index.vue'),
    meta: {
        keepAlive: false,
        requiresMobile: true,
    },
}, {
    path: '/order',
    component: () =>
        import('@/pages/mobile/order/index.vue'),
    meta: {
        title: '我的订单',
        keepAlive: false,
        requiresMobile: true,
    },
}, {
    path: '/queryOrder',
    component: () =>
        import('@/pages/mobile/order/queryOrder.vue'),
    meta: {
        keepAlive: false,
        requiresMobile: true,
    },
}, {
    path: '/order_detail',
    component: () =>
        import('@/pages/mobile/order/orderDetail.vue'),
    meta: {
        keepAlive: false,
        requiresMobile: true,
    },
}, {
    path: '/visitor_order_detail',
    component: () =>
        import('@/pages/mobile/order/visitor_order_detail.vue'),
    meta: {
        keepAlive: false,
        requiresMobile: true,
    },
}, {
    path: '/detail',
    component: () =>
        import('@/pages/mobile/orderDetail.vue'),
    meta: {
        keepAlive: false,
        requiresMobile: true,
    },
}, {
    path: '/revenueDetail',
    component: () =>
        import('@/pages/mobile/revenueDetail.vue'),
    meta: {
        keepAlive: false,
        requiresMobile: true,
    },
},
{
    path: '/mpriceLog',
    name: 'mpriceLog',
    component: () =>
        import('@/pages/mobile/priceLog.vue'),
    meta: {
        title: '调价信息',
        keepAlive: false,
        requiresMobile: true,
    },
    props: true
},
// {
//     path: '/tuiguang',
//     component: () =>
//         import ('@/pages/mobile/tuiguang.vue'),
//     meta: {
//         keepAlive: false,
//         requiresMobile: true,
//     },
// },
{
    path: '/mbalance',
    component: () =>
        import('@/pages/mobile/balance.vue'),
    meta: {
        keepAlive: false,
        requiresMobile: true,
    },
    props: true
},
{
    path: '/recharge',
    component: () =>
        import('@/pages/mobile/recharge.vue'),
    meta: {
        keepAlive: false,
        requiresMobile: true,
    },
    props: true
},
{
    path: '/mwithdrawal',
    component: () =>
        import('@/pages/mobile/mwithdrawal.vue'),
    meta: {
        keepAlive: false,
        requiresMobile: true,
    },
    props: true
},
{
    path: '/mNoticeDetails/:id',
    name: 'mNoticeDetails',
    component: mNoticeDetails,
    meta: {
        keepAlive: true,
        requiresMobile: true,
    },
    props: true
},
{
    path: '/bangzhuzhongxin',
    component: () =>
        import('@/pages/mobile/bangzhuzhongxin.vue'),
    meta: {
        keepAlive: true,
        requiresMobile: true,
    },
    props: true
},
{
    path: '/sysNotice',
    component: () =>
        import('@/pages/mobile/sysNotice.vue'),
    meta: {
        keepAlive: true,
        requiresMobile: true,
    },
    props: true
}, {
    path: '/mselfPay/:id/:rechargeId',
    component: () =>
        import('@/pages/mobile/ZhiZhuPay.vue'),
    meta: {
        keepAlive: false,
    },
},
{
    path: '/commission',
    component: () =>
        import('@/pages/mobile/commission.vue'),
    meta: {
        keepAlive: true,
        requiresMobile: true,
    },
    props: true
},
//收货地址
{
    path: '/receivingAddress',
    component: () =>
        import('@/pages/mobile/receivingAddress.vue'),
    meta: {
        keepAlive: true,
        requiresMobile: true,
    },
    props: true
},
//收货地址
{
    path: '/mConfirmPayment',
    component: () =>
        import('@/pages/mobile/payment/confirmPayment.vue'),
    meta: {
        keepAlive: true,
        requiresMobile: true,
    },
    props: true
},
//重置密码
{
    path: '/resetpwd',
    component: () =>
        import('@/pages/mobile/resetpwd.vue'),
    meta: {
        keepAlive: true,
        requiresMobile: true,
    },
    props: true
},
];




// PC端路由配置
const pcRoutes = [{
    path: '/plogin',
    component: () =>
        import('@/pages/pc/login.vue'),
    meta: {
        keepAlive: true,
        requiresPC: true
    },
    props: true
}, {
    path: '/page',
    component: () =>
        import('@/pages/page.vue'),
    props: true,
    meta: {
        keepAlive: true,
        requiresPC: true,
    },

    children: [{
        path: '/puser',
        component: () =>
            import('@/pages/pc/user.vue'),
        meta: {
            title: '用户中心',
            keepAlive: false,
            requiresPC: true,
        },
        children: [{
            path: '/messageSubscribe',
            component: () =>
                import('@/pages/pc/message_subscribe.vue'),
            meta: {
                title: '消息订阅',
                keepAlive: false,
                requiresPC: true,
            },
        }, {
            path: '/porder',
            component: () =>
                import('@/pages/pc/myorder.vue'),
            meta: {
                title: '我的订单',
                keepAlive: false,
                requiresPC: true,
            },
        }, {
            path: '/user_info',
            component: () =>
                import('@/pages/pc/userInfo.vue'),
            meta: {
                title: '用户信息',
                keepAlive: false,
                requiresPC: true,
            }
        }, {
            path: '/service',
            component: () =>
                import('@/pages/pc/service.vue'),
            meta: {
                keepAlive: false,
                requiresPC: true,
            }
        }, {
            path: '/balance',
            component: () =>
                import('@/pages/pc/balance.vue'),
            meta: {
                title: '我的余额',
                keepAlive: false,
                requiresPC: true,
            }
        },
        {
            //开放分站
            path: '/openFz',
            component: () =>
                import('@/pages/pc/openFz.vue')
        },

        {
            path: '/credit',
            component: () =>
                import('@/pages/pc/credit.vue'),
            meta: {
                keepAlive: false,
                requiresPC: true,
            }
        }, {
            path: '/cash',
            component: () =>
                import('@/pages/pc/cash.vue'),
            meta: {

                keepAlive: false,
                requiresPC: true,
            }
        },
        {
            path: '/profit',
            component: () =>
                import('@/pages/pc/profit.vue')
        }, {
            path: '/settlement',
            component: () =>
                import('@/pages/pc/settlement.vue')
        },
        // {
        //     path: '/fz/banner',
        //     component: () =>
        //         import ('@/pages/pc/fz/banner/banner.vue')
        // }, {
        //     path: '/fz/mBanner',
        //     component: () =>
        //         import ('@/pages/pc/fz/banner/mBanner.vue')
        // }, 
        {
            path: '/convertManage',
            component: () =>
                import('@/pages/pc/convertManage.vue'),
            meta: {
                keepAlive: false,
                requiresPC: true,
            }
        },
        {
            path: '/code',
            component: () =>
                import('@/pages/pc/code.vue'),
            meta: {
                keepAlive: false,
                requiresPC: true,
            }
        },
        {
            path: '/invited',
            component: () =>
                import('@/pages/pc/invited.vue'),
            meta: {
                keepAlive: false,
                requiresPC: true,
            }
        },
        //收货地址
        {
            path: '/address',
            component: () =>
                import('@/pages/pc/address.vue'),
            meta: {
                keepAlive: false,
                requiresPC: true,
            },
            props: true
        },
        ],
    }, {
        path: '/notice',
        component: () =>
            import('@/pages/pc/notice.vue'),
        meta: {
            title: '系统公告',
            keepAlive: true,
            requiresPC: true,
        },
        props: true
    }, {
        path: '/noticeDetails/:id',
        name: 'noticeDetails',
        component: () =>
            import('@/pages/pc/noticeDetails.vue'),
        meta: {
            title: '公告详情',
            keepAlive: true,
            requiresPC: true,
        },
        props: true
    },
    {
        path: '/pcate',
        component: () =>
            import('@/pages/pc/pcate.vue'),
        meta: {
            title: '商品分类',

            keepAlive: true,
            requiresPC: true
        },
        props: true
    }, {
        path: '/customer',
        component: () =>
            import('@/pages/pc/customer.vue'),
        meta: {
            title: '客服中心',
            keepAlive: true,
            requiresPC: true
        },
        props: true
    }, {
        path: '/pgoodsDetail/:id',
        component: () =>
            import('@/pages/pc/pgoodsDetail.vue'),
        meta: {
            keepAlive: true,
            requiresPC: true
        },
        props: true
    }, {
        path: '/confirmOrder',
        component: () =>
            import('@/pages/pc/confirmOrder.vue'),
        meta: {
            title: '确认订单',
            keepAlive: true,
            requiresPC: true
        },
        props: true
    }, {
        path: '/confirmPayment',
        component: () =>
            import('@/pages/pc/confirmPayment.vue'),
        meta: {
            title: '支付订单',
            keepAlive: true,
            requiresPC: true
        },
        props: true
    }, {
        path: '/plist',
        name: 'plist',
        component: () =>
            import('@/pages/pc/plist.vue'),
        meta: {
            keepAlive: true,
            requiresPC: true
        },
        props: true
    }, {
        path: '/priceLog',
        name: 'priceLog',
        component: () =>
            import('@/pages/pc/priceLog.vue'),
        meta: {
            title: '调价信息',
            keepAlive: true,
            requiresPC: true
        },
        props: true
    }, {
        path: '/index',
        component: () =>
            import('@/pages/index.vue'),
        meta: {
            keepAlive: true,
            requiresPC: true,
        },
        props: true,
    }, {
        path: '/reset',
        component: Reset
    },
    //自助收款
    {
        path: '/selfPay/:id/:rechargeId',
        component: () =>
            import('@/pages/pc/ZhiZhuPay.vue'),
        meta: {
            keepAlive: false,
        },
    },

    ],
},

{
    path: '/footer',
    component: () =>
        import('@/pages/pc/common/footer.vue'),
    meta: {
        keepAlive: true,
        requiresPC: true
    },
    props: true
},
];


// 公共组件
const commonRoutes = [{
    path: '/',
    component: () =>
        import('@/pages/home.vue'),
    props: true
},
{
    path: '/install',
    component: () =>
        import('@/pages/install.vue'),
    props: true
},
{
    path: '/error',
    component: () =>
        import('@/pages/error.vue'),
    props: true
},
{
    path: '/thirdlogin',
    component: () =>
        import('@/pages/pc/thirdlogin.vue'),
    meta: {
        keepAlive: true,
    },
    props: true
},
{
    path: '/scanLogin',
    component: () =>
        import('@/pages/pc/scanLogin.vue'),
    meta: {
        keepAlive: true,
    },
    props: true
}, {
    path: '/cdk',
    component: () =>
        import('@/pages/cdk.vue'),
    meta: {
        keepAlive: false,
    },
}, {
    path: '/call_order',
    component: () =>
        import('@/pages/callback/order.vue'),
    meta: {
        keepAlive: false,
    },
},
{
    path: '/call_recharge',
    component: () =>
        import('@/pages/callback/recharge.vue'),
    meta: {
        keepAlive: false,
    },
},

// 等级价格查询
{
    path: '/levelPrice',
    component: () =>
        import('@/pages/common/levelPrice.vue'),
    meta: {
        keepAlive: false,
    },
},

// 订单查询系统
{
    path: '/query',
    component: () =>
        import('@/pages/common/queryorder.vue'),
    props: true

},
];


const vipRoutes = [{
    path: '/viplogin',
    component: () =>
        import('@/pages/vip/login.vue'),
    meta: {
        keepAlive: false,
    },
},
{
    path: '/vip',
    component: () =>
        import('@/pages/vip/index.vue'),
    props: true,
    meta: {
        keepAlive: false,
    },
    children: [{
        //分站首页
        path: '/vip',
        component: () =>
            import('@/pages/vip/dataStatis.vue')
    },
    {
        //分站信息
        path: '/vip/sys',
        component: () =>
            import('@/pages/vip/sys.vue')
    },
    {
        //分站客服
        path: '/vip/customer',
        component: () =>
            import('@/pages/vip/customer.vue')
    },
    {
        //首页弹框
        path: '/vip/homeUp',
        component: () =>
            import('@/pages/vip/homeUp.vue')
    },
    {
        //同步分站商品
        path: '/vip/product',
        component: () =>
            import('@/pages/vip/product.vue')
    },

    {
        //分站订单
        path: '/vip/Order',
        component: () =>
            import('@/pages/vip/order.vue')
    },
    {
        //用户管理
        path: '/vip/user',
        component: () =>
            import('@/pages/vip/user.vue')
    },
    {
        //用户管理
        path: '/vip/commissionDetail',
        component: () =>
            import('@/pages/vip/commissionDetail.vue')
    },
    {
        //PC端轮播图
        path: '/vip/banner/banner',
        component: () =>
            import('@/pages/vip/banner/banner.vue')
    },
    {
        //PC端轮播图
        path: '/vip/banner/mBanner',
        component: () =>
            import('@/pages/vip/banner/mBanner.vue')
    },
    {
        //下级分站列表
        path: '/vip/vipManage/vipList',
        component: () =>
            import('@/pages/vip/vipManage/vipList.vue')
    },

    {
        //分站套餐
        path: '/vip/vipManage/version',
        component: () =>
            import('@/pages/vip/vipManage/version.vue')
    }, {
        //分站装修
        path: '/vip/template',
        component: () =>
            import('@/pages/vip/template.vue')
    }, {
        //域名管理
        path: '/vip/domain',
        component: () =>
            import('@/pages/vip/domain.vue')
    },
    ]
},
];



const whiteList = ['/login', '/reset', '/sign', '/cart', '/order_form_create'] // no redirect whitelist
const createRouter = () => new VueRouter({
    mode: 'history',
    // scrollBehavior: () => ({ y: 0 }),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes: [
        ...mobile,
        ...pcRoutes,
        ...commonRoutes,
        ...vipRoutes
    ]
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}




router.beforeEach(async (to, from, next) => {

    //获取推荐参数
    const inviterCode = to.query.inviter_code;

    // 将参数保存到缓存中
    if (inviterCode) {
        localStorage.setItem('inviterCode', inviterCode);
    }

    if (from.path.includes("/product_detail")) {
        // 这个name是下一级页面的路由name  true说明你是返回到这个页面，而不是通过跳转从其他页面进入到这个页面
        to.meta.isRefresh = true;
    }


    if (to.meta.title) {
        document.title = to.meta.title
    } else {
        document.title = Vue.prototype.$globalVar;
    }


    if (isMobile()) {
        // 如果在移动端访问PC端页面，则重定向到移动端对应页面
        if (to.matched.some(record => record.meta.requiresPC)) {
            next({ path: '/mindex' })
        } else {
            next()
        }
    } else {
        // 如果在PC端访问移动端页面，则重定向到PC端对应页面
        if (to.matched.some(record => record.meta.requiresMobile)) {
            next({ path: '/index' })
        } else {
            next()
        }
    }


    //获取登录信息
    const hasToken = getToken()
    if (hasToken) {
        // 获取认证状态
        const isAuthenticated = localStorage.getItem('isCardAuth') === 'true'
        if (isAuthenticated && window.location.pathname !== '/puser') {
            Message({
                message: localStorage.getItem('cardAuthTip'),
                type: 'info',
                duration: 5 * 1000
            });
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            //console.log("----")
        }
    }
})

//判断是否是移动端
function isMobile() {

    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    return flag;
}


router.afterEach(() => { })

export default router;